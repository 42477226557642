const PropTypes = require('prop-types');
const React = require('react');
const classNames = require('classnames');

function Section(props) {
  return (
    <section className={classNames(props.type, props.className)} style={props.style} type={props.type}>
      {props.children}
    </section>
  );
}

Section.defaultProps = {
  children: null,
  className: null,
  style: null,
  type: null,
};

Section.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.object,
};

module.exports = Section;
