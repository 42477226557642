const isInternal = (target, domain) => {
  if (!target || !domain) {
    return false;
  }

  if (target.charAt(0) === '/') {
    return true;
  }

  try {
    const parseTarget = new URL(target).hostname.replace('www.', '');
    const cleanDomain = domain.replace('www.', '');
    return parseTarget === cleanDomain;
  } catch {
    return false;
  }
};

module.exports = { isInternal };
